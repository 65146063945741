import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from '../components/Layout';
import PageBanner from '../components/PageBanner';
import YAMLData from '../../static/content/services.yml';
import SEO from "../components/SEO";

function Services() {
  const imageData = useStaticQuery(graphql`
    query {
      services: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 600
              )
            }
          }
        }
      }
      banners: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 1400
              )
            }
          }
        }
      }
    }
  `);

  const {
    servicesList,
    heading,
    image,
    image_alt = ''
  } = YAMLData;

  servicesList.map((service, i) => {
    let serviceImage = imageData.services.edges.find(
      serviceImage => "/assets/"+serviceImage.node.relativePath === service.image
    )
    service.imageSrc = serviceImage.node.childImageSharp.gatsbyImageData;
    service.isOdd = (i % 2);
    return service;
  });

  const headerImage = imageData.banners.edges.find(
    headerImage => "/assets/"+headerImage.node.relativePath === image
  );

  const ServiceImage = ({service}) => {
    return (
      <div className="image">
        <GatsbyImage alt={service.image_alt}
          image={service.imageSrc} />
      </div>
    )
  }

  return (
    <Layout>
      <SEO title="Fair Beauty - Services | Waxing, Facials, Threading."
        description="Our talented and professional beauty therapists specialise in and offer a range of services, including waxing, facials, microblading, eyebrow henna, eyelash lifting, eyelash extensions & spray tanning." />
      <PageBanner image={headerImage.node.childImageSharp.gatsbyImageData}
        imageAlt={image_alt}
        heading={heading} />

      <section className="section">
        <div className="container grid is-vertical">
          <div className="column">
            {servicesList.map((service, i) => (
              <div className="media"
                key={i}>
                {!service.isOdd
                  ? <div className="media__left is-tablet-up">
                    <ServiceImage service={service} />
                  </div>
                  : ''
                }
                <div className="media__content">
                  <div className="is-mobile-only"
                    style={{paddingBottom: '10px'}}>
                    <ServiceImage service={service} />
                  </div>
                  <h4>{service.heading}</h4>
                  <p className="has-white-space">{service.info}</p>
                </div>
                {service.isOdd
                  ? <div className="media__right is-tablet-up">
                    <ServiceImage service={service} />
                  </div>
                  : ''
                }
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Services;
